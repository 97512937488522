import React from 'react'

import { Link, graphql } from 'gatsby'

import Layout from '../../components/Layout'

export default class ReportsIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <div
          className="full-width-image-container margin-top-0"
          style={{
            backgroundImage: `url('/img/blog-index.jpg')`,
          }}
        >
          <h1
            className="has-text-weight-bold is-size-1"
            style={{
              boxShadow:
                'rgb(50, 115, 220) 0.5rem 0px 0px, rgb(50, 115, 220) -0.5rem 0px 0px',
              backgroundColor: 'rgb(50, 115, 220)',
              color: 'white',
              padding: '1rem',
            }}
          >
            相关报道
          </h1>
        </div>
        <section className="section">
          <div className="container content">
            <div className="columns">
              <div
                className="column is-10 is-offset-1"
                style={{ marginBottom: '6rem' }}
              >
                <h1 className="title is-size-3 is-bold-light">2021</h1>
                <ul className="taglist">
                  <li>
                    <Link
                      target="_blank"
                      to="https://ncov.dxy.cn/ncovh5/view/pneumonia"
                    >
                      <p className="is-size-4">新型冠状病毒肺炎疫情实时动态</p>
                    </Link>
                  </li>
                </ul>
                <ul className="taglist">
                  <li>
                    <Link
                      target="_blank"
                      to="https://m.chinanews.com/wap/detail/chs/zw/151527.shtml"
                    >
                      <p className="is-size-4">
                        加拿大友人网上新春晚会读解“甘肃文化”
                      </p>
                    </Link>
                  </li>
                </ul>
                <h1 className="title is-size-3 is-bold-light">2020</h1>
                <ul className="taglist">
                  <li>
                    <Link
                      target="_blank"
                      to="http://wsb.gansu.gov.cn/web/article/4028805f701d8efb01701dc9a6480023.html"
                    >
                      <p className="is-size-4 is-bold-light">
                        旅加侨胞募捐支持甘肃抗击疫情 - 甘肃省人民政府外事办公室
                      </p>
                    </Link>
                  </li>
                </ul>
                <h1 className="title is-size-3 is-bold-light">2018</h1>
                <ul className="taglist">
                  <li>
                    <Link
                      target="_blank"
                      to="http://canada.haiwainet.cn/n/2018/0417/c3542303-31300047.html"
                    >
                      <p className="is-size-4">
                        加拿大甘肃总商会在多伦多正式成立 - 人民日报海外网
                      </p>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
